import { Div } from "../../../base/components/native/div";
import { ICollection } from "../../../interfaces/collections";
import { Artwork } from "./artwork";

export const Collection = (collection: ICollection) => {

    const base = Div()

    const title = Div(collection.title)
    const contents = Div()
    base.append(title, contents)

    collection.artworks
        .sort((a, b) => {
            const wa = a.dimensions.match(/\d+/g)
            const wb = b.dimensions.match(/\d+/g)
            return (parseInt(wa?.[0] || '0') * parseInt(wa?.[1] || '0')) - (parseInt(wb?.[0] || '0') * parseInt(wb?.[1] || '0'))

        })
        .forEach(artwork => {
            contents.append(Artwork(artwork))
        })

    title.cssClass({
        fontSize: '48px',
        fontWeight: '100',
        margin: '12px 0',
    })

    contents.cssClass({
        display: 'flex',
        flexWrap: 'wrap',
        // display: 'grid',
        // gridTemplateColumns: 'repeat(auto-fill, minmax(145px, 1fr))',
        gap: '30px',
    })

    return base

}