import idb from '../base/lib/idb'
import ldb from '../base/lib/ldb'
import { emitter } from '../base/utils/emitter'

const db = idb('azad-db')
const init = () => {
    // check if initiated
    if (ldb.get('init')) return Promise.resolve(true)
    return new Promise(async (resolve, reject) => {
        if (!ldb.get('init')) ldb.save('init', true)
        emitter.emit('db-ready')
        return resolve(true)
    })
}
export default {
    ...db,
    init
}