import app from './app'
import { disableTouchStartPassive } from './base/utils/event-handlers'
// import { disableContextMenu, disableTouchStartPassive } from './base/helpers/events'
import db from './services/db'

(async () => {
    window.onerror = (message, source, lineno, colno, error) => {
        alert(message)
    }
    await db.init()
    // disableContextMenu({ touch: true, mouse: false })
    disableTouchStartPassive()
    document.body.appendChild(app.el)
})()
