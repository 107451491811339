import { Div } from "../../../base/components/native/div";
import { Img } from "../../../base/components/native/img";
import { IArtwork } from "../../../interfaces/collections";

export const Artwork = (artwork: IArtwork) => {
    const base = Div()

    const title = Div(artwork.title)
    const img = Img(artwork.imageUrl, { width: 130 })
    const desc = Div(artwork.description)
    const dimensions = Div(artwork.dimensions)
    const price = Div('€ ' + artwork.price)
    base.append(img, title, dimensions, desc, price)

    // use regex to get the number from the string 20x40cm
    const dimensionsArray = artwork.dimensions.match(/\d+/g)
    const width = parseInt(dimensionsArray?.[0] || '0')
    const height = parseInt(dimensionsArray?.[1] || '0')
    console.log(width, height);


    title.cssClass({
        fontSize: '18px',
        color: 'gray',
        fontWeight: '100',
        marginTop: '10px',
        width: width * 5 + 'px',
    })
    desc.cssClass({
        fontSize: '12px',
        color: 'gray',
        wordSpacing: '2px',
        fontWeight: '100',
    })
    dimensions.cssClass({
        fontSize: '14px',
        color: 'gray',
        wordSpacing: '2px',
        fontWeight: '100',
    })
    img.cssClass({
        filter: 'drop-shadow(0px 3px 6px #00000088)',
        outline: '3px solid white',
        cursor: 'pointer',
        '&:hover': {
            filter: 'drop-shadow(0px 3px 6px #000000cc)',
        },
    })
    img.style({
        width: width * 5 + 'px',
        height: height * 5 + 'px',
    })

    // on image click open modal with image full size
    img.el.onclick = () => {
        const modal = Div()
        const modalImg = Img(artwork.imageUrl)
        modal.append(modalImg)
        modal.cssClass({
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            padding: '20px',
            backgroundColor: 'rgba(0,0,0,0.9)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1000',
            transition: 'opacity 0.3s',
        })
        modalImg.cssClass({
            maxHeight: '100%',
            maxWidth: '100%',
        })
        const x = Div('✕')
        x.cssClass({
            color: 'white',
            position: 'absolute',
            top: '20px',
            right: '20px',
            cursor: 'pointer',
        })
        base.append(modal)
        modal.append(x)
        modal.el.onclick = () => modal.remove()
        x.el.onclick = () => modal.remove()
    }

    return base
}