export default {
    MOBILE: 425,
    TABLET: 768,
    DESKTOP: 1024,
    WIDESCREEN: 1216,
    FULLHD: 1408,
    LEFT_MARGIN: 20,
    TOP_MARGIN: 9, // ios?
    ICON_WIDTH: 112
}

